<template>
  <div class="city">
    <div class="city-name" @click="goHome('厦门')">厦门</div>
    <div class="city-box">
      <div class="city-item" @click="goHome(item.areaName)" v-for="(item, index) in cityList" :key="index">
        {{ item.areaName }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "city",
  data() {
    return {
      cityList: [],
    };
  },
  mounted(){
    this.getCity();
  },
  methods:{
      goHome(city){
          this.$router.push({path:'/',query: {city}})
      },
      getCity(){
        console.log("你好");
        this.$post("home/getCity").then((res=>{
          if(res.status == 200){
            console.log(res.data);
            this.cityList = res.data
          }
        }))
      }
  }
};
</script>
<style lang="less" scoped>
.city {
  .city-name {
    color: #333;
    font-weight: 700;
    height: 44px;
    line-height: 44px;
    padding-left: 10px;
    font-size: 18px;
    border-top: 1px solid #eeeeee;
    background: #fafafa;
  }
  .city-box{
      background-color: #fff;
      padding: 0 20px;
      .city-item{
          font-size: 17px;
          color: #333;
          line-height: 44px;
          border-top: 1px solid #eeeeee;
          &:first-child{
              border-top: 0;
          }
      }

  }
}
</style>